/* RESPONSIBLE TEAM: team-frontend-tech */

import type Store from '@ember-data/store';
import { type Block } from 'embercom/models/common/blocks/block';

export default function (store: Store, block: any, blockIndex?: any, parentId?: any): Block | void {
  if (store.isDestroying) {
    return;
  }

  let modelClass = `common/blocks/${block.type}`;
  let normalized = store.normalize(modelClass, block) as { data: { attributes: any } };
  let normalizedBlock = normalized.data.attributes;

  if (parentId) {
    let blockId = `${parentId}-${blockIndex}`;
    let existingFragment = store
      .peekAll(modelClass)
      .find((fragment) => fragment?.blockId === blockId && fragment.get('type') === block.type);
    if (existingFragment) {
      existingFragment.setProperties(normalizedBlock);
      return existingFragment;
    } else {
      let fragment = store.createFragment(modelClass, { ...normalizedBlock }) as Block;
      fragment.set('blockId', blockId);
      return fragment;
    }
  }

  let fragment = store.createFragment(modelClass, { ...normalizedBlock }) as Block;
  return fragment;
}
