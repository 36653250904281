/* RESPONSIBLE TEAM: team-knowledge-foundations */

import {
  BaseConfig,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_ORDERED_NESTED_LIST,
  INPUT_RULE_UNORDERED_NESTED_LIST,
  INPUT_RULE_INLINE_CODE,
} from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from './embercom-file-uploader';
import {
  type TextDirection,
  type Analytics,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { type Heading } from '@intercom/interblocks.ts';
import { type UploadConfig } from '@intercom/embercom-prosemirror-composer/addon/lib/config/composer-config';
import { EntityType } from 'embercom/models/data/entity-types';
import type ComposerConfig from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
export default class ArticlesComposerConfig extends BaseConfig {
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    uploader: EmbercomFileUploader,
  } as UploadConfig;
  typeaheads = [EMOJI_TYPEAHEAD];
  placeholder = 'Start writing…';
  showAllTextAlignment = true;
  analytics: Analytics;
  formatters?: ComposerConfig['formatters'];

  constructor(
    app: any,
    textDirection: TextDirection = 'ltr',
    analytics: Analytics,
    anchorLinkOnClick: (heading: Heading) => void,
    useAnchorLinkArticleInserter = false,
    showMediaToolbar = true,
  ) {
    super();
    this.upload.attrs = { policyUrl: `/apps/${app.id}/uploads` };
    this.upload.allowedAttachmentTypes = ['*'];

    this.experimental.inlineResizeImages = true;

    this.textDirection = textDirection;

    let tools: any[] = [
      { name: 'text-formatter' },
      {
        name: useAnchorLinkArticleInserter ? 'content-anchor-editor' : 'anchor-editor',
        options: useAnchorLinkArticleInserter
          ? {
              useTeammateUrls: useAnchorLinkArticleInserter,
              entityTypes: [EntityType.ArticleContent],
            }
          : {},
      },
      { name: 'video-editor' },
      { name: 'button-editor' },
      { name: 'image-editor', options: { supportAltAttributeEditing: true } },
      { name: 'anchor-link-copy-button', options: { onClick: anchorLinkOnClick } },
      { name: 'table-editor' },
      { name: 'color-popover-editor' },
      { name: 'collapsible-toggle' },
    ];

    if (showMediaToolbar) {
      tools.push({ name: 'media-toolbar', options: { showListToolbarIcon: true } });
    }

    let blocks = [
      'paragraph',
      'image',
      'inlineImage',
      'orderedNestedList',
      'unorderedNestedList',
      'heading',
      'subheading',
      'subheading3',
      'subheading4',
      'codeBlock',
      'video',
      'button',
      'horizontalRule',
      'table',
      'callout',
      'collapsibleSection',
    ];

    this.allowedInline.push('inlineImage');

    this.tables = {
      resizeColumns: true,
      responsive: false,
      containers: false,
    };

    this.inputRules = [
      INPUT_RULE_CODE_BLOCK,
      INPUT_RULE_EMOJI,
      INPUT_RULE_ORDERED_NESTED_LIST,
      INPUT_RULE_UNORDERED_NESTED_LIST,
      INPUT_RULE_INLINE_CODE,
    ];

    this.tools = tools;
    this.allowedBlocks = blocks;
    this.analytics = analytics;
    this.isInteractivityDisabled = false;
  }
}
