/* RESPONSIBLE TEAM: team-reporting */
import ChartDataResourceCompatible from 'embercom/lib/reporting/chart-data-resource-compatible';
import DataConfigBuilder from 'embercom/lib/reporting/custom/data-config-builder';
import ViewConfigBuilder from 'embercom/lib/reporting/custom/view-config-builder';
import HighchartsDataBuilder from 'embercom/lib/reporting/flexible/highcharts-data-builder';
import TableDataConfigBuilder from 'embercom/lib/reporting/custom/table-data-config-builder';
import { getOwner } from '@ember/application';
import { zip } from 'underscore';
import { isPresent } from '@ember/utils';
import { cached } from 'tracked-toolbox';
interface PercentageMetricValue {
  value: number | null;
  numeratorValue: number;
  denominatorValue: number;
}

type FlexibleResponsePercentageMetricValue = PercentageMetricValue & { key: string };
type FlexibleResponseMetricValue = FlexibleResponsePercentageMetricValue | number;
interface SignalFlexibleResponseViewBy {
  groups: Array<{
    values: string[];
    aggregations: Array<{
      name: string;
      values: number[];
      processedValues?: PercentageMetricValue[];
    }>;
    name: string;
    type: string;
  }>;
}

export type MetricValue = number | PercentageMetricValue;
type FlatResponseData = Array<[key: string, value: MetricValue]>;

function flattenResponseData(responseData: SignalFlexibleResponseViewBy): FlatResponseData {
  let data = responseData.groups[0] || responseData;
  let keys = data.values;
  let aggregations = data.aggregations[0];
  let values = aggregations.processedValues || aggregations.values;
  return zip(keys, values) as FlatResponseData;
}

function isPercentageMetricValue(
  value: FlexibleResponseMetricValue,
): value is FlexibleResponsePercentageMetricValue {
  return (
    typeof value === 'object' &&
    (isPresent(value.numeratorValue) || isPresent(value.denominatorValue))
  );
}

function buildMetricValue(value: FlexibleResponseMetricValue): MetricValue {
  if (isPercentageMetricValue(value)) {
    return {
      value: value.value,
      numeratorValue: value.numeratorValue,
      denominatorValue: value.denominatorValue,
    };
  }
  return value;
}

export default class ChartDataResource extends ChartDataResourceCompatible {
  constructor(owner: unknown, args: any) {
    let { renderableChart, onError } = args.named || args;

    let dataConfig = renderableChart.isTable
      ? new TableDataConfigBuilder(renderableChart).buildDataConfig()
      : new DataConfigBuilder(renderableChart).buildDataConfig();
    super(owner, { dataConfig, onError, renderableChart });

    this.viewConfig = new ViewConfigBuilder(renderableChart, owner).buildViewConfig();
  }

  viewConfig?: any;

  @cached
  get flatData() {
    return this.rawChartData.map(flattenResponseData);
  }

  @cached
  get flatCombinedData() {
    let lookups = this.flatData.map(Object.fromEntries);
    let allKeys = new Set(lookups.flatMap(Object.keys));
    let results: Record<string, MetricValue[]> = {};
    for (let key of allKeys) {
      let valuesForAllMetrics = lookups.map((lookup) => buildMetricValue(lookup[key]));
      results[key] = valuesForAllMetrics;
    }
    return results;
  }

  get kpiData() {
    return Object.values(this.flatCombinedData)[0];
  }

  @cached
  get chartData() {
    if (!this.rawChartData) {
      return [];
    }

    if (this.renderableChart.stacked) {
      // stacked charts
      return new HighchartsDataBuilder(
        getOwner(this),
        this.dataConfig,
        this.viewConfig,
      ).forStackedChartResponse(this.rawChartData);
    }

    return this.rawChartData.map((dataResponse) => {
      if (this.renderableChart.viewBy === null) {
        // simple count
        return {
          name: dataResponse.name,
          data: [dataResponse.aggregations[0].values[0]],
          requestName: dataResponse.name,
        };
      } else if (this.renderableChart.stacked === false) {
        // bar/line chart
        let group = dataResponse.groups[0];
        let aggregation = group.aggregations[0];

        return group.values.map((gv: any, index: number) => {
          let x = gv;
          let y = aggregation.values[index];
          let name = group?.names?.get(index);
          return [x, y, name];
        });
      }
    });
  }
}
